<template>
  <b-form-group
    :label="$t('Logo Yükle')"
    label-for="image-upload"
  >
    <b-form-file
      id="image-upload"
      v-model="newStartup.upload_image"
      placeholder="Bir görsel seçin veya buraya bırakın..."
      drop-placeholder="Görseli buraya bırakın..."
      browse-text="Görsel Seçin"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormFile } from 'bootstrap-vue'

export default {
  name: 'LogoImage',
  components: {
    BFormGroup,
    BFormFile,
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
  },
}
</script>

<style scoped>

</style>
