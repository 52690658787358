<template>
  <b-row>
    <b-col class="overflow-y-scroll height-200 bg-white pt-2 pb-2">
      <div
        v-html="startupConfig.terms"
      />
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Problems',
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      dataQuery: {
        select: [
          'startup_configs.terms AS terms',
        ],
        where: {
          'startup_configs.id': 1,
        },
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    startupConfig() {
      return this.$store.getters['appStartupConfigs/getStartupConfig']
    },
  },
  created() {
    this.getStartupConfig()
  },
  methods: {
    getStartupConfig() {
      this.$store.dispatch('appStartupConfigs/startupConfigView')
    },
  },
}
</script>

<style scoped></style>
