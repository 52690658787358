<template>
  <validation-provider
    #default="{ errors }"
    :name="$t('Girişimin Websitesi')"
    rules="required|url"
  >
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('Girişimin Websitesi')"
          label-for="website"
        >
          <b-form-input
            id="website"
            v-model="newStartup.website"
            :placeholder="$t('Girişimin Websitesi')"
            class="mb-2"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'StartupWebsite',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
}
</script>

<style scoped></style>
