<template>
  <b-form-group
    :label="$t('Şirket Adı ve Ünvanı')"
    label-for="company"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t('Şirket Adı ve Ünvanı')"
      rules="required"
    >
      <b-form-input
        id="company"
        v-model="newStartup.company"
        :placeholder="$t('Şirket Adı ve Ünvanı')"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Company',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
}
</script>

<style scoped>

</style>
