<template>
  <validation-provider
    #default="{ errors }"
    :name="$t('Diğer (%)')"
    rules="required"
  >
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('Diğer (%)')"
          label-for="other_percent"
        >
          <b-input-group append="%">
            <cleave
              id="other_percent"
              v-model="newStartup.other_percent"
              class="form-control"
              :raw="false"
              :options="options.customDelimiter"
              :placeholder="$t('Diğer (%)')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BRow, BCol, BFormGroup, BInputGroup,
} from 'bootstrap-vue'

export default {
  name: 'OtherPercent',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    Cleave,
    BInputGroup,
  },
  data() {
    return {
      options: {
        customDelimiter: {
          blocks: [3],
        },
      },
    }
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
}
</script>

<style scoped></style>
