<template>
  <validation-provider
    #default="{ errors }"
    :name="$t('İş Tanımı')"
    rules="required"
  >
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('İş Tanımı')"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="newStartup.description"
            :placeholder="$t('İş Tanımı')"
            class="mb-2"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'Description',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
}
</script>

<style scoped></style>
