import { render, staticRenderFns } from "./Capex_percent.vue?vue&type=template&id=f879da48&scoped=true&"
import script from "./Capex_percent.vue?vue&type=script&lang=js&"
export * from "./Capex_percent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f879da48",
  null
  
)

export default component.exports