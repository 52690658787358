<template>
  <div>
    <b-row
      v-for="(item, index) in newStartup.startup_references"
      :key="index"
    >

      <b-col md="5">
        <b-form-group
          :label="$t('Referans Adı Soyadı')"
          :label-for="'rname_' + index"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Referans Adı Soyadı')"
            rules="required"
          >
            <b-form-input
              :id="'rname_' + index"
              v-model="item.name"
              type="text"
              :placeholder="$t('Adı Soyadı')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('Girişim / Kurum Adı')"
          :label-for="'rtitle_' + index"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Girişim / Kurum Adı')"
            rules="required"
          >
            <b-form-input
              :id="'rtitle_' + index"
              v-model="item.company"
              type="text"
              :placeholder="$t('Girişim / Kurum Adı')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- Remove Button -->
      <b-col
        v-if="index > 0"
        cols="auto"
        class="mb-50"
      >
        <b-button
          variant="outline-danger"
          class="mt-0 mt-md-2"
          @click="removeItem(index)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-25"
          />
          <span>{{ $t('Sil') }}</span>
        </b-button>
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      @click="addReference"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{ $t('Ekle') }}</span>
    </b-button>
  </div>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  name: 'StartupTeams',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
  methods: {
    addReference() {
      this.newStartup.startup_references.push({
        company: null,
        name: null,
      })
    },
    removeItem(index) {
      this.newStartup.startup_references.splice(index, 1)
    },
  },
}
</script>
