<template>
  <validation-observer ref="simpleRules">
    <div>
      <b-card>
        <startup-title />
        <company />
      </b-card>
      <b-card :title="$t('Faaliyet Alanı')">
        <activity-areas />
      </b-card>
      <b-card :title="$t('Pazarlama Odağı')">
        <marketing-focus />
      </b-card>
      <b-card :title="$t('Hedef Müşteriler')">
        <target-customers />
      </b-card>
      <b-card :title="$t('Girişimin Websitesi')">
        <startup-website />
      </b-card>
      <b-card :title="$t('Girişimin ya da Girişimcinin E-Posta Adresi')">
        <startup-email />
      </b-card>
      <b-card :title="$t('Girişimin Linkedin Adresi')">
        <startup-linkedin />
      </b-card>
      <b-card :title="$t('İş Tanımı')">
        <description />
      </b-card>
      <b-card :title="$t('İş Açıklaması')">
        <contents />
      </b-card>
      <b-card :title="$t('Problemler')">
        <problems />
      </b-card>
      <b-card :title="$t('Çözümler')">
        <solutions />
      </b-card>
      <b-card :title="$t('Gelir Modeli')">
        <revenue-model />
      </b-card>
      <b-card :title="$t('Rekabet Avantajları')">
        <competitive-advantages />
      </b-card>
      <b-card :title="$t('Pazar')">
        <market />
      </b-card>
      <b-card :title="$t('Alınan Ödül, Yatırım, Teşvik ve Destekler')">
        <award-supports />
      </b-card>
      <b-card :title="$t('Talep Edilen Yatırım Miktarı')">
        <demand-investment />
      </b-card>
      <b-card>
        <b-card-title>
          <div>{{ $t('Ar-Ge (R&D) (%)') }}</div>
          <small class="text-primary">{{ $t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)') }}</small>
        </b-card-title>
        <rd-percent />
      </b-card>
      <b-card>
        <b-card-title>
          <div>{{ $t('Pazarlama & Satış & Dağıtım(S & M)( %)') }}</div>
          <small class="text-primary">{{ $t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)') }}</small>
        </b-card-title>
        <sm-percent />
      </b-card>
      <b-card>
        <b-card-title>
          <div>{{ $t('Genel Yönetim (G&A) (%)') }}</div>
          <small class="text-primary">{{ $t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)') }}</small>
        </b-card-title>
        <ga-percent />
      </b-card>
      <b-card>
        <b-card-title>
          <div>{{ $t('Satılan Mal Maliyeti (COGS) (%)') }}</div>
          <small class="text-primary">{{ $t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)') }}</small>
        </b-card-title>
        <cogs-percent />
      </b-card>
      <b-card>
        <b-card-title>
          <div>{{ $t('OPEX Operasyon Giderleri (%)') }}</div>
          <small class="text-primary">{{ $t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)') }}</small>
        </b-card-title>
        <opex-percent />
      </b-card>
      <b-card>
        <b-card-title>
          <div>{{ $t('CAPEX Sermaye Giderleri (%)') }}</div>
          <small class="text-primary">{{ $t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)') }}</small>
        </b-card-title>
        <capex-percent />
      </b-card>
      <b-card>
        <b-card-title>
          <div>{{ $t('Diğer( %)') }}</div>
          <small class="text-primary">{{ $t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)') }}</small>
        </b-card-title>
        <other-percent />
      </b-card>
      <b-card :title="$t('Ekip')">
        <startup-teams />
      </b-card>
      <b-card :title="$t('Referanslar')">
        <startup-references />
      </b-card>
      <b-card :title="$t('Hüküm ve Koşullar')">
        <terms />
      </b-card>
      <b-card :title="$t('Logo Yükleme')">
        <logo-image />
      </b-card>
      <div>
        <b-form-checkbox
          v-model="draftStatus"
          class="mb-1"
          switch
        >
          {{ $t('Taslak Olarak Kaydet') }}
        </b-form-checkbox>
        <draft-save-alert v-if="draftStatus" />
      </div>
      <template v-if="draftStatus">
        <b-button
          variant="warning"
          @click="saveDraft"
        >
          {{ $t('Taslak Olarak Kaydet') }}
        </b-button>
      </template>
      <template v-else>
        <b-button
          variant="primary"
          @click="submitForm"
        >
          {{ $t('Başvuruyu Tamamla') }}
        </b-button>
      </template>
    </div>
  </validation-observer>
</template>

<script>
import {
  BCard, BButton, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import StartupTitle from '@/views/App/NewStartups/New/Title.vue'
import Company from '@/views/App/NewStartups/New/Company.vue'
import ActivityAreas from '@/views/App/NewStartups/New/Activity_areas.vue'
import MarketingFocus from '@/views/App/NewStartups/New/Marketing_focus.vue'
import targetCustomers from '@/views/App/NewStartups/New/Target_customers.vue'
import startupWebsite from '@/views/App/NewStartups/New/Startup_website.vue'
import startupEmail from '@/views/App/NewStartups/New/Startup_email.vue'
import startupLinkedin from '@/views/App/NewStartups/New/Startup_linkedin.vue'
import description from '@/views/App/NewStartups/New/Description.vue'
import contents from '@/views/App/NewStartups/New/Contents.vue'
import problems from '@/views/App/NewStartups/New/Problems.vue'
import solutions from '@/views/App/NewStartups/New/Solutions.vue'
import revenueModel from '@/views/App/NewStartups/New/Revenue_model.vue'
import competitiveAdvantages from '@/views/App/NewStartups/New/Competitive_advantages.vue'
import market from '@/views/App/NewStartups/New/Market.vue'
import awardSupports from '@/views/App/NewStartups/New/Award_supports.vue'
import demandInvestment from '@/views/App/NewStartups/New/Demand_investment.vue'
import rdPercent from '@/views/App/NewStartups/New/Rd_percent.vue'
import smPercent from '@/views/App/NewStartups/New/Sm_percent.vue'
import gaPercent from '@/views/App/NewStartups/New/Ga_percent.vue'
import cogsPercent from '@/views/App/NewStartups/New/Cogs_percent.vue'
import opexPercent from '@/views/App/NewStartups/New/Opex_percent.vue'
import capexPercent from '@/views/App/NewStartups/New/Capex_percent.vue'
import otherPercent from '@/views/App/NewStartups/New/Other_percent.vue'
import startupTeams from '@/views/App/NewStartups/New/Startup_teams.vue'
import startupReferences from '@/views/App/NewStartups/New/Startup_references.vue'
import terms from '@/views/App/NewStartups/New/Terms.vue'
import LogoImage from '@/views/App/NewStartups/New/LogoImage.vue'
import DraftSaveAlert from '@/views/App/NewStartups/components/DraftSaveAlert.vue'

export default {
  name: 'New',
  components: {
    DraftSaveAlert,
    BFormCheckbox,
    BCard,
    BButton,
    BCardTitle,
    ValidationObserver,
    StartupTitle,
    Company,
    ActivityAreas,
    MarketingFocus,
    targetCustomers,
    startupWebsite,
    startupEmail,
    startupLinkedin,
    description,
    contents,
    problems,
    solutions,
    revenueModel,
    competitiveAdvantages,
    market,
    awardSupports,
    demandInvestment,
    rdPercent,
    smPercent,
    gaPercent,
    cogsPercent,
    opexPercent,
    capexPercent,
    otherPercent,
    startupTeams,
    startupReferences,
    terms,
    LogoImage,
  },
  data() {
    return {
      required,
      draftStatus: false,
    }
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
    saveStatus() {
      return this.$store.getters['newStartups/getNewStartupSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.$store.commit('newStartups/RESET_NEW_STARTUP_VIEW')
    localize(this.$i18n.locale)
  },
  mounted() {
    this.getData()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
        .then(() => {
          this.$router.push('/girisim-basvurularim')
        })
    },
    getData() {
      this.$store.dispatch('newStartups/newStartupView', this.$route.params.id)
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.newStartup.is_draft = 0
          this.$store.dispatch('newStartups/newStartupSave', this.newStartup)
        }
      })
    },
    saveDraft() {
      this.newStartup.is_draft = 1
      this.$store.dispatch('newStartups/newStartupSave', this.newStartup)
    },
  },
}
</script>
