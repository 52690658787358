<template>
  <div>
    <validation-provider
      #default="{ errors }"
      :name="$t('Pazarlama Odağı')"
      rules="required"
    >
      <b-row>
        <b-col
          v-for="(item,key) in marketingFocuses"
          :key="key"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="newStartup.startup_marketing_focus"
              :value="item.id"
            >
              {{ item.title }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
    <b-row class="mt-1">
      <b-col>
        <b-form-group>
          <b-form-checkbox
            v-model="other"
            value="1"
          >
            {{ $t('Diğer') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col
        v-if="other"
        cols="12"
      >
        <b-form-group
          :label="$t('Diğer')"
          label-for="others"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Diğer')"
            rules="required"
          >
            <b-form-input
              id="others"
              v-model="newStartup.startup_marketing_focus['text']"
              :placeholder="$t('Diğer')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BRow, BCol, BFormCheckbox, BFormInput, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'MarketingFocus',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      other: 0,
    }
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
    marketingFocuses() {
      return this.$store.getters['appMarketingFocus/getMarketingFocuses']
    },
  },
  created() {
    localize(this.$i18n.locale)
    this.getMarketingFocuses()
  },
  methods: {
    getMarketingFocuses() {
      this.$store.dispatch('appMarketingFocus/marketingFocusesList')
    },
  },
}
</script>

<style scoped></style>
