<template>
  <div>
    <validation-provider
      #default="{ errors }"
      :name="$t('Faaliyet Alanı')"
      rules="required"
    >
      <b-row>
        <b-col
          v-for="(activityArea,key) in activityAreas"
          :key="key"
          md="4"
          class="mb-2"
        >
          <b-form-checkbox
            :id="activityArea.id"
            v-model="newStartup.startup_activity_area"
            :value="activityArea.id"
            :disabled="newStartup.startup_activity_area.length === 4 && !newStartup.startup_activity_area.includes(activityArea.id)"
          >
            {{ activityArea.title }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'ActivityAreas',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormCheckbox,
  },
  computed: {
    newStartup() {
      return this.$store.getters['newStartups/getNewStartup']
    },
    activityAreas() {
      return this.$store.getters['appActivityAreas/getActivityAreas']
    },
  },
  created() {
    localize(this.$i18n.locale)
    this.getActivityAreas()
  },
  methods: {
    getActivityAreas() {
      this.$store.dispatch('appActivityAreas/activityAreasList')
    },
  },
}
</script>

<style scoped></style>
